import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'arsultima-markdown-helper-info',
  templateUrl: './markdown-helper-info.component.html',
  styleUrls: ['./markdown-helper-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownHelperInfoComponent implements OnInit {
  fields = ['title1', 'title2', 'title3', 'italic', 'bold', 'bulletedList', 'numberedList'];

  constructor(private sanitizer: DomSanitizer, private translocoService: TranslocoService) {}

  ngOnInit(): void {}

  getTranslation(field: string, type = 'example') {
    return this.sanitizeHtml(this.translocoService.translate(`markdownHelper.${field}.${type}`));
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
