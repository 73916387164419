import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'arsultima-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogHeaderComponent implements OnInit {
  @Output()
  closeClicked = new EventEmitter<void>();

  @Input()
  title: string;

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.closeClicked.emit();
  }
}
