import { Component, Input, OnInit } from '@angular/core';

import { Preferences } from '../../modules/settings/models/settings.model';

@Component({
  selector: 'arsultima-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  @Input()
  client: Preferences;

  constructor() {}

  ngOnInit(): void {}
}
