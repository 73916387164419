<arsultima-message-bar></arsultima-message-bar>
<arsultima-header
  (signoutClicked)="signout()"
  [client]="client"
  [lang]="lang$ | async"
  [userConnexion]="userConnexion$ | async"
></arsultima-header>
<section class="main-container">
  <router-outlet></router-outlet>
</section>
<arsultima-footer></arsultima-footer>
