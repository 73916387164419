import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'arsultima-ui-button-edit-contextual',
  templateUrl: './ui-button-edit-contextual.component.html',
  styleUrls: ['./ui-button-edit-contextual.component.scss'],
})
export class UiButtonEditContextualComponent implements OnInit {
  @Input()
  tooltip = '';

  @Input()
  showLabel = true;

  @Input()
  onHover = true;

  @Input()
  darkTheme = false;

  constructor() {}

  ngOnInit(): void {}
}
