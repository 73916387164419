import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { KeyValue } from '../../../models/key-value.model';

@Component({
  selector: 'arsultima-form-select-field',
  templateUrl: './form-select-field.component.html',
  styleUrls: ['./form-select-field.component.scss'],
})
export class FormSelectFieldComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  field: string;

  @Input()
  label: string;

  @Output()
  addClicked = new EventEmitter();

  @Input()
  fieldWidthClass = 'field-width-select';

  _options: KeyValue[] = [];

  constructor() {}

  @Input()
  set options(optionsList: KeyValue[]) {
    this._options = this.addEmptyOption(optionsList);
  }

  get addTooltip(): string {
    return `Add a ${this.label}`;
  }

  ngOnInit(): void {}

  emitAdd(): void {
    this.addClicked.emit();
  }

  private addEmptyOption(options: KeyValue[]): KeyValue[] {
    return [{ key: null, value: '' }, ...options];
  }
}
