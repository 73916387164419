import { Currency } from './models/currency.enum';

export const ARS_SUB_DOMAIN = 'arsu';
export const DEFAULT_CURRENCY = Currency.EUR;
export const DEFAULT_CURRENCY_CODE = 'EUR';

// REGEX Patterns
export const REGEX_YEAR = '^[12][0-9]{3}$';
export const REGEX_PHONE = '\\+?(([0-9]|\\([0-9]\\))([-.]?|[ ]*)){5,20}';
export const REGEX_SITE = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
export const LARGE_NUMBER_OF_ITEMS = 1000000;
