import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'arsultima-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss'],
})
export class FormButtonsComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  disableSave: boolean = false;

  @Input()
  disableCancel: boolean = false;

  @Input()
  disableDelete: boolean = false;

  @Input()
  showDelete: boolean = false;

  @Input()
  alignCenter = false;

  @Input()
  cancelLabel = 'buttons.back';

  @Input()
  saveLabel = 'buttons.save';

  @Input()
  deleteLabel = 'buttons.delete';

  @Output()
  cancelClicked = new EventEmitter();

  @Output()
  deleteClicked = new EventEmitter();

  @Output()
  saveClicked = new EventEmitter();

  constructor(
    readonly translocoService: TranslocoService, //  private readonly translocoService: TranslocoService
  ) {}

  // Combine specific conditions passed to the component
  // and conditions deduced directly from passed form object
  get disableSaveCombined(): boolean {
    if (this.form) {
      // Disable save : manually trough disableSave specify the disable state
      // If the form is not dirty (nothing to save) it is disabled
      // If the form is not valid, it is also disabled
      return this.disableSave || !this.form?.dirty || !this.form?.valid;
    }
    return this.disableSave;
  }

  ngOnInit(): void {}

  cancel() {
    this.cancelClicked.emit();
  }

  delete() {
    this.deleteClicked.emit();
  }

  save() {
    this.saveClicked.emit();
  }
}
