import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { IMAGE_FIELDS_DIM, IMAGE_FIELDS_SIZE } from './image-field.config';

import { IMAGE_PREFIXES } from '../../../models/image.model';
import { Utils } from '../../../utils/utils';

@Component({
  selector: 'arsultima-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageFieldComponent implements OnInit {
  @Input()
  src: string;

  imagePopover = true; // If image is using the popover zoom option

  imageStyle = IMAGE_FIELDS_SIZE.SMALL;
  imageStyleZoom = IMAGE_FIELDS_SIZE.MEDIUM;

  imageUrlWithRefresh: string;
  mediumImageSrcWithForceRefresh: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges({ src }: SimpleChanges): void {
    if (src && src.currentValue) {
      this.imageUrlWithRefresh = Utils.addRefreshParamToImage(src.currentValue);
      this.mediumImageSrcWithForceRefresh = this.imageUrlWithRefresh.replace(
        IMAGE_PREFIXES.small,
        IMAGE_PREFIXES.medium,
      );
    }
  }

  get placeholderSrc(): string {
    return `assets/image-placeholder.svg`;
  }

  getImageWidth(style = this.imageStyle): number {
    return IMAGE_FIELDS_DIM[style].width;
  }

  getImageHeight(style = this.imageStyle): number {
    return IMAGE_FIELDS_DIM[style].height;
  }
}
