import { LOCAL_STORAGE_EMPTY_VALUE } from './../config/api.config';
export interface UserConnexion {
  db: string; // db to query
  connectedUserDb?: string; // db of the connextion (may differ from the connected db)
  email: string;
  token: string;
  site: SiteType;
  canEdit: boolean;
  clientFromUrl?: SiteType;
}

export type UserConnexionInStorage = UserConnexion | typeof LOCAL_STORAGE_EMPTY_VALUE;

export class DomainInfo {
  domain: string;
  db: string;

  constructor(domain: string, db: string) {
    this.domain = domain;
    this.db = db;
  }
}

export const NO_TOKEN = 'notoken';

export enum SiteType {
  CLIENT = 'client', // We are connected and we visit our own client site
  CLIENT_FROM_URL = 'clientFromUrl', // We visit a collection of another arsultima client wheter we are connected or not
  ARSU = 'arsultima', // We visit the arsultima site about the service
}

export enum DB {
  ARSU = 'arsu',
  CFAG = 'cfag',
  LIND = 'lind',
}

export enum ConnexionErrors {
  WRONG_IDENTIFIERS = 'id_wrong',
}
