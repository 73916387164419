<div class="select-field flex-center" [formGroup]="form">
  <mat-form-field class="{{ fieldWidthClass }}">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControlName]="field">
      <mat-option *ngFor="let option of _options | sortByValue" [value]="option.key">
        {{ option.value }}
      </mat-option>
    </mat-select>
    <arsultima-clear-field matSuffix [form]="form" [field]="field"></arsultima-clear-field>
  </mat-form-field>
  <button
    class="m-l-md icon-medium button-icon"
    mat-icon-button
    (click)="emitAdd()"
    [matTooltip]="addTooltip"
    matTooltipPosition="above"
  >
    <mat-icon class="icon-medium">add</mat-icon>
  </button>
</div>
