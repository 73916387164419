import { CommonModule } from '@angular/common';
import { FilterFieldComponent } from './filter-field.component';
import { FilterPipe } from './filter-pipe/filter-pipe.pipe';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [FilterFieldComponent, FilterPipe],
  exports: [FilterFieldComponent, FilterPipe],
  imports: [CommonModule, FormsModule, MatFormFieldModule, MatInputModule, MatIconModule, TranslocoModule],
})
export class FilterFieldModule {}
