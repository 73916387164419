import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'arsultima-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.scss'],
})
export class EmailSentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
