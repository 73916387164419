import { RouterModule, Routes } from '@angular/router';

import { AppConfig } from './app.config';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { NgModule } from '@angular/core';
import { SiteType } from './models/auth.model';
import { ThemeDemoComponent } from './theme/theme-demo/theme-demo.component';
import { URL_ROUTER } from './utils/url-router';

const routes: Routes = [
  { path: '', redirectTo: URL_ROUTER.arsultimaPrefix(), pathMatch: 'full' },
  { path: '**', redirectTo: URL_ROUTER.exhibitBase() },

  // Public page of each collections
  {
    path: `${URL_ROUTER.publicCollectionPrefix()}/:collectionName`,
    children: [
      {
        path: URL_ROUTER.exhibitBase(),
        loadChildren: () => import('./modules/exhibit/exhibit.module').then(m => m.ExhibitModule),
      },
      {
        path: URL_ROUTER.home(),
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
        data: { type: SiteType.CLIENT },
      },
      {
        path: URL_ROUTER.contact(),
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
      },
    ],
  },

  // Arsultima app pages public pages
  {
    path: `${URL_ROUTER.arsultimaPrefix()}`,
    children: [
      {
        path: URL_ROUTER.home(),
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
      },

      // Missing is the discover tab
      {
        path: URL_ROUTER.contact(),
        loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule),
        data: { db: AppConfig.DEFAULT_DB, site: SiteType.ARSU },
      },
      { path: '', redirectTo: URL_ROUTER.home(), pathMatch: 'full' },
    ],
  },
  {
    path: URL_ROUTER.database(),
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/database/database.module').then(m => m.DatabaseModule),
  },
  {
    path: URL_ROUTER.stats(),
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule),
  },
  {
    path: URL_ROUTER.viewer(),
    loadChildren: () => import('./modules/slideshow/slideshow.module').then(m => m.SlideshowModule),
  },
  {
    path: URL_ROUTER.slide(),
    canActivate: [AuthGuard],
    loadChildren: () => import('./shared/slide/slide.module').then(m => m.SlideModule),
  },
  {
    path: URL_ROUTER.block(),
    canActivate: [AuthGuard],
    loadChildren: () => import('./shared/block/block.module').then(m => m.BlockModule),
  },
  {
    path: URL_ROUTER.connect(),
    loadChildren: () => import('./modules/connect/connect.module').then(m => m.ConnectModule),
  },
  {
    path: URL_ROUTER.settings(),
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'styles',
    component: ThemeDemoComponent,
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
