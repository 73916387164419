import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from './../../authentication/services/auth/auth.service';
import { Injectable } from '@angular/core';
import { NavigateService } from './../../authentication/services/navigate/navigate.service';
import { Observable } from 'rxjs';
import { URL_ROUTER } from './../../../utils/url-router';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  debug = true;
  constructor(private authService: AuthService, private readonly navigateService: NavigateService) {}
  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('Auth guard, are we connected', this.authService.isConnected());
    if (this.authService.isConnected()) {
      return true;
    } else {
      if (this.debug) {
        console.log(
          'NAVIGATE REDIRECT CALL : we have just redirected because of access of unauthorized page while disconnected',
        );
      }
      this.navigateService.navigate(URL_ROUTER.homeArsultima());
      return false;
    }
  }
}
