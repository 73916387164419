import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'arsultima-inline-message',
  templateUrl: './inline-message.component.html',
  styleUrls: ['./inline-message.component.scss'],
})
export class InlineMessageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
