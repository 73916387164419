import { LOCAL_STORAGE_EMPTY_VALUE, LOCAL_STORAGE_KEYS } from '../../../../config/api.config';

import { AppConfig } from '../../../../app.config';
import { Injectable } from '@angular/core';
import { LangKeys } from 'apps/arsultima/src/app/config/lang.config';
import { UserConnexionInStorage } from '../../../../models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  debug = false;
  constructor() {}

  // Client key
  setClientLocalStorage(value: string) {
    this.setLocalStorage(AppConfig.LOCALSTORAGE_CLIENT_KEY, value);
  }

  deleteClientLocalStorage() {
    this.setLocalStorage(AppConfig.LOCALSTORAGE_CLIENT_KEY, LOCAL_STORAGE_EMPTY_VALUE);
  }

  // User key
  setUserLocalStorage(value: string) {
    localStorage.setItem(AppConfig.LOCALSTORAGE_USER_KEY, value);
  }

  deleteUserLocalStorage() {
    this.setLocalStorage(AppConfig.LOCALSTORAGE_USER_KEY, LOCAL_STORAGE_EMPTY_VALUE);
  }

  setCollectionLocalStorage(value: string) {
    localStorage.setItem(AppConfig.LOCALSTORAGE_COLLECTION_KEY, value);
  }

  deleteCollectionLocalStorage() {
    this.setLocalStorage(AppConfig.LOCALSTORAGE_COLLECTION_KEY, LOCAL_STORAGE_EMPTY_VALUE);
  }

  setExhibitLocalStorage(value: string, db: string) {
    localStorage.setItem(this.getExhibitKey(db), value);
  }

  deleteExhibitLocalStorage(db: string) {
    this.setLocalStorage(this.getExhibitKey(db), LOCAL_STORAGE_EMPTY_VALUE);
  }

  getConnectedUser(): UserConnexionInStorage {
    return this.getLocalStorage(AppConfig.LOCALSTORAGE_USER_KEY);
  }

  getClient() {
    return this.getLocalStorage(AppConfig.LOCALSTORAGE_CLIENT_KEY);
  }

  getExhibit(db: string) {
    return this.getLocalStorage(this.getExhibitKey(db));
  }

  getLang() {
    return this.getLocalStorage(AppConfig.LOCALSTORAGE_LANG);
  }

  setLang(lang: LangKeys) {
    return this.setLocalStorage(AppConfig.LOCALSTORAGE_LANG, lang);
  }

  clear(): void {
    this.deleteClientLocalStorage();
    this.deleteUserLocalStorage();
    this.deleteCollectionLocalStorage();
  }

  private getExhibitKey(db: string): string {
    return AppConfig.LOCALSTORAGE_EXHIBIT_KEY + '.' + db;
  }

  // All local storage operation use finally this private
  // function, so we can easily debug
  private setLocalStorage(key: LOCAL_STORAGE_KEYS | string, value: string) {
    if (this.debug) {
      console.log(`Local storage ${key} SET to ${value}`);
    }
    localStorage.setItem(key, value);
  }

  // To value is already parsed
  private getLocalStorage(key: LOCAL_STORAGE_KEYS | string): any {
    let value = localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {
      // If it's not a valid JSON string, it will throw an error and we just leave the value as is
      // AS in some case, it could be a simple string and not a JSON
      // So no need to parse it
    }
    if (this.debug) {
      console.log(`Local storage ${key} SET `, value);
    }
    return value;
  }
}
