import { Preferences, PreferencesPayload, SelectListWrapper } from '../modules/settings/models/settings.model';

import { ApiResponse } from '../models/api.model';
import { DataService } from 'apps/arsultima/src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URL } from '../utils/url';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private readonly http: HttpClient, private readonly dataService: DataService) {}

  uploadLogo({ file, clientId }: { file: File; clientId: string }): Observable<any> {
    const url = `${URL.clientLogoUpload(clientId)}`;
    const params = this.dataService.getParamsWithConnexion();
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<ApiResponse<any>>(url, formData, { params });
    // .pipe(map(response => new CollectorData(response.data)));
    // return of({});
  }

  getPreferences(): Observable<Preferences> {
    // not sure how to properly do this but routeDbOverride overrides
    // the db when identifying the defaultDb in getCurrentUserConnection()
    // so we need to manually pass the default db
    // const params = this.dataService.getParamsWithConnexion({ defaultDb: user.db });
    const params = this.dataService.getParamsWithConnexion();

    const url = URL.getPreferences;

    return this.http
      .get<ApiResponse<Preferences>>(url, { params })
      .pipe(map(response => response.data));
  }

  savePreferences(payload: PreferencesPayload): Observable<Preferences> {
    const params = this.dataService.getParamsWithConnexion();

    return this.http
      .put<ApiResponse<Preferences>>(URL.getPreferences, { data: payload }, { params })
      .pipe(map(response => response.data));
  }

  getSelectLists(): Observable<SelectListWrapper> {
    const params = this.dataService.getParamsWithConnexion();
    const url = URL.getSelectLists;

    return this.http
      .get<ApiResponse<SelectListWrapper>>(url, { params })
      .pipe(map(response => response.data));
  }

  saveSelectLists(payload: SelectListWrapper): Observable<SelectListWrapper> {
    const params = this.dataService.getParamsWithConnexion();

    return this.http
      .put<ApiResponse<SelectListWrapper>>(URL.getSelectLists, { data: payload }, { params })
      .pipe(map(response => response.data));
  }
}
