export const STYLES = [
  // Styles per function
  {
    title: 'Page title',
    className: 'text-page-title',
    description: 'Used for page header',
  },
  {
    title: 'Tab label',
    className: 'text-tab-label',
    description: 'Used for tabs label (Material tabs)',
  },
  {
    title: 'Form group title',
    className: 'text-form-group-title',
  },
  {
    title: 'Section header',
    className: 'text-header',
    description: '',
  },
  {
    title: 'Form Labels',
    className: 'text-form-label',
    description: 'Label generally for <Label></label> element',
  },
  {
    title: 'Dialog header',
    className: 'text-dialog-header',
    description: 'Users for dialog headers',
  },

  // Utility class to add colors to text
  {
    className: 'text-color-gold',
    title: 'Gold text',
    description: '',
  },
  {
    className: 'text-color-blue-dark',
    title: 'Gold text',
    description: '',
  },

  {
    className: 'text-bigger-text',
    title: 'Big text',
    description: '',
  },
  {
    className: 'text-medium-text',
    title: 'Medium text',
    description: '',
  },

  {
    className: 'text-small-text',
    title: 'Small text',
    description: '',
  },
];
