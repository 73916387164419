import { AddEditDeleteComponent } from './add-edit-delete/add-edit-delete.component';
import { AddListItemComponent } from './add-list-item/add-list-item.component';
import { ArsultimaPipesModule } from '../pipes/arsultima-pipes.module';
import { BlockSeparatorComponent } from './block-separator/block-separator.component';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { ClearFieldComponent } from './clear-field/clear-field.component';
import { CommonModule } from '@angular/common';
import { CreateEditButtonComponent } from './create-edit-button/create-edit-button.component';
import { DialogComponent } from './dialog/dialog.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';
import { EditCreateButtonComponent } from './edit-create-button/edit-create-button.component';
import { FilterFieldModule } from './filter-field/filter-field.module';
import { FormButtonsComponent } from './form-buttons/form-buttons.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { FormLinkFieldComponent } from './form-link-field/form-link-field.component';
import { FormSelectFieldComponent } from './form-select-field/form-select-field.component';
import { HomeCardsComponent } from './home-cards/home-cards.component';
import { ImageFieldComponent } from './image-field/image-field.component';
import { ListFilterComponent } from './list-filter/list-filter.component';
import { MarkdownHelperComponent } from './markdown-helper/markdown-helper.component';
import { MarkdownHelperInfoComponent } from './markdown-helper-info/markdown-helper-info.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MessageBarComponent } from './message-bar/message-bar.component';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';
import { NgModule } from '@angular/core';
import { PageTitleComponent } from './page-title/page-title.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ResetButtonComponent } from './reset-button/reset-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { UiButtonEditContextualComponent } from './ui-button-edit-contextual/ui-button-edit-contextual.component';
import { InlineMessageComponent } from './inline-message/inline-message.component';

// Prefer decompose the UI in sub-module
// The same as material is doing
// To allow limit the size of the compiled bundle
const SUB_MODULES = [FilterFieldModule];
@NgModule({
  declarations: [
    AddEditDeleteComponent,
    AddListItemComponent,
    BlockSeparatorComponent,
    CancelDialogComponent,
    ClearFieldComponent,
    EditCreateButtonComponent,
    FormButtonsComponent,
    FormHeaderComponent,
    HomeCardsComponent,
    ImageFieldComponent,
    ListFilterComponent,
    ResetButtonComponent,
    UiButtonEditContextualComponent,
    CreateEditButtonComponent,
    FormFieldErrorComponent,
    FormLinkFieldComponent,
    FormSelectFieldComponent,
    FormErrorComponent,
    PageTitleComponent,
    MarkdownHelperComponent,
    MarkdownHelperInfoComponent,
    DialogHeaderComponent,
    DialogComponent,
    MessageBarComponent,
    InlineMessageComponent,
  ],
  exports: [
    AddEditDeleteComponent,
    AddListItemComponent,
    BlockSeparatorComponent,
    ClearFieldComponent,
    FormButtonsComponent,
    FormHeaderComponent,
    HomeCardsComponent,
    ImageFieldComponent,
    ListFilterComponent,
    MatMenuModule,
    ResetButtonComponent,
    UiButtonEditContextualComponent,
    CreateEditButtonComponent,
    FormFieldErrorComponent,
    FormLinkFieldComponent,
    FormSelectFieldComponent,
    FormErrorComponent,
    PageTitleComponent,
    MarkdownHelperComponent,
    DialogHeaderComponent,
    DialogComponent,
    MessageBarComponent,
    InlineMessageComponent,

    ...SUB_MODULES,
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    //Material
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,

    // Others libs
    MtxPopoverModule, // Used by imageField component

    // Custom
    ArsultimaPipesModule,
    ...SUB_MODULES,
    TranslocoModule,
  ],
  entryComponents: [CancelDialogComponent],
})
export class UiModule {}
