import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'arsultima-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
})
export class FormHeaderComponent implements OnInit {
  @Input()
  title: string;

  @Input()
  showClose = true;

  @Output()
  goBackClicked = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  goBack() {
    this.goBackClicked.emit();
  }
}
