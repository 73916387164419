import { CommonModule } from '@angular/common';
import { LangDisplayPipe } from './lang-display/lang-display.pipe';
import { NgModule } from '@angular/core';
import { SortByValuePipe } from './sort-by-value/sort-by-value.pipe';

@NgModule({
  declarations: [SortByValuePipe, LangDisplayPipe],
  imports: [CommonModule],
  exports: [SortByValuePipe, LangDisplayPipe],
})
export class ArsultimaPipesModule {}
