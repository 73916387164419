import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'arsultima-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.scss'],
})
export class FilterFieldComponent implements OnInit {
  @Input()
  filterValue: string;

  searchLabel: 'Search';

  @Output()
  filterChanged = new EventEmitter<string>();

  constructor(private translocoService: TranslocoService) {}

  get filterActive(): boolean {
    return !!this.filterValue;
  }

  ngOnInit(): void {
    const translation = this.translocoService.translate('components.search');
    this.searchLabel = translation;
  }

  onFilterChange() {
    this.filterChanged.emit(this.filterValue);
  }

  resetFilter() {
    this.filterValue = '';
    this.filterChanged.emit(this.filterValue);
  }
}
