import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { KeyValue } from '../../../models/key-value.model';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'arsultima-form-link-field',
  templateUrl: './form-link-field.component.html',
  styleUrls: ['./form-link-field.component.scss'],
})
export class FormLinkFieldComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  field: string;

  @Input()
  label: string;

  @Input()
  createDisabled = false;

  @Input()
  createHidden = false;

  @Input()
  editDisabled = false;

  @Input()
  editHidden = false;

  @Input()
  infoTooltip: string;

  @Output()
  editClicked = new EventEmitter<string>();

  @Output()
  createClicked = new EventEmitter();

  _options: KeyValue[] = [];

  constructor(private readonly translocoService: TranslocoService) {}

  ngOnInit(): void {}

  @Input()
  set options(options: KeyValue[]) {
    if (!!options) {
      this._options = this.addEmptyOption(options);
    }
  }

  get fieldOption(): string {
    return this._options.find(option => option.key === this.fieldValue || '')?.value;
  }

  get fieldValue(): string {
    return this.form?.get(this.field)?.value || '';
  }

  get isEditDisabled(): boolean {
    return !this.fieldValue || this.editDisabled;
  }

  get isCreateDisabled(): boolean {
    return this.createDisabled;
  }

  get createTooltip(): string {
    const createNew = this.translocoService.translate('buttons.create');

    return `${createNew}`;
  }

  get editTooltip(): string {
    const edit = this.translocoService.translate('buttons.edit');
    return this.fieldOption ? `${edit} ${this.fieldOption}` : edit;
  }

  emitEdit(): void {
    this.editClicked.emit(this.fieldValue);
  }

  emitCreate(): void {
    this.createClicked.emit();
  }

  private addEmptyOption(options: KeyValue[]): KeyValue[] {
    return [{ key: null, value: '' }, ...options];
  }
}
