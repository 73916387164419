import { MESSAGE_BAR_DEFAULT, MessageBarState } from './message-bar.interface';

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MessageBarService {
  private stateSubject = new BehaviorSubject<MessageBarState>(MESSAGE_BAR_DEFAULT);
  state$ = this.stateSubject.asObservable();

  constructor() {}

  // Method to update the full message state
  setMessage(newState: MessageBarState): void {
    this.stateSubject.next(newState);
  }

  setDefault() {
    this.stateSubject.next(MESSAGE_BAR_DEFAULT);
  }
}
