<!-- Simple version without the zoom -->
<ng-container *ngIf="!imagePopover && !!src">
  <img [src]="src" width="{{ getImageWidth() }}" height="{{ getImageHeight() }}" />
</ng-container>

<!-- Image plus zoom on click -->
<ng-container *ngIf="imagePopover && !!src">
  <img
    [src]="imageUrlWithRefresh"
    width="{{ getImageWidth() }}"
    height="{{ getImageHeight() }}"
    #popoverTrigger="mtxPopoverTrigger"
    [mtxPopoverTriggerFor]="popover"
    [mtxPopoverTargetAt]="'mtxPopover'"
    mtxPopoverTriggerOn="click"
  />
</ng-container>

<!-- Image placeholder is case of no SRC information -->
<ng-container *ngIf="!src">
  <img [src]="placeholderSrc" width="{{ getImageWidth() }}" height="{{ getImageHeight() }}" />
</ng-container>

<mtx-popover #popover="mtxPopover" [closeOnPanelClick]="true">
  <img
    [src]="mediumImageSrcWithForceRefresh"
    width="{{ getImageWidth(imageStyleZoom) }}"
    height="{{ getImageHeight(imageStyleZoom) }}"
  />
</mtx-popover>
