import { Component, OnInit } from '@angular/core';
import { MESSAGE_BAR_DEFAULT, MessageBarState } from './message-bar.interface';

import { MessageBarService } from './message-bar.service';

@Component({
  selector: 'arsultima-message-bar',
  templateUrl: './message-bar.component.html',
  styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent implements OnInit {
  state: MessageBarState = MESSAGE_BAR_DEFAULT;

  constructor(private messageBarService: MessageBarService) {}

  ngOnInit(): void {
    // Subscribe to the state object
    this.messageBarService.state$.subscribe(state => {
      this.state = state;
    });
  }

  hideBar() {
    this.messageBarService.setDefault();
  }
}
