export enum LangKeys {
  fr = 'fr',
  en = 'en',
}
export const APP_LANGUAGES = [LangKeys.en, LangKeys.fr];

export const APP_DEFAULT_LANG = LangKeys.en;

export const APP_LANGUAGES_DISPLAY = {
  [LangKeys.en]: 'English',
  [LangKeys.fr]: 'Français',
};

export const CLIENT_LANG_PROPERTY = 'language';
