<section>
  <div class="section-title">Html tags</div>

  <h1>Title h1</h1>
  <h2>Title h2</h2>
  <h3>Title h3</h3>
  <h4>Title h4</h4>
  <div>Text with a <a href="https://www.google.com">link</a></div>
  <br />
  <p class="text-bigger-text">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam maximus risus at hendrerit faucibus. Proin pellentesque
    justo sed convallis finibus.
  </p>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam maximus risus at hendrerit faucibus. Proin pellentesque
    justo sed convallis finibus. Integer in lacus sed sapien fringilla scelerisque malesuada nec risus. Sed sed erat a
    urna pellentesque pellentesque id quis felis. Sed egestas tortor sit amet iaculis facilisis. Cras sed fringilla
    nulla, quis lacinia quam. Mauris consectetur scelerisque ornare. Nunc porta justo et ipsum interdum, et dictum est
    euismod. Curabitur sit amet arcu volutpat, vehicula ligula ornare, laoreet urna. Pellentesque dapibus consectetur
    augue, sed dictum nulla tincidunt in.
  </p>
</section>

<section>
  <div class="section-title">Specific app text</div>
  <div *ngFor="let style of styles" class="item">
    <span [ngClass]="[style.className, 'example-text']">{{ style.title }}</span>
    <div class="m-l-md">
      <div class="class-name">.{{ style.className }}</div>
      <p>{{ style.description }}</p>
    </div>
  </div>
</section>

<section>
  <div class="section-title">Tabs menu</div>
  <div class="class-name">.navigation-menu</div>
  <ul class="navigation-menu">
    <li class="nav-item">
      <a class="nav-link">Home Slider</a>
    </li>
    <li class="nav-item active">
      <a class="nav-link">Your profile</a>
    </li>
    <li class="nav-item">
      <a class="nav-link">Select lists</a>
    </li>
  </ul>
</section>
