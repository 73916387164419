<span
  class="pointer"
  *ngIf="!hasFieldValue"
  matTooltip="{{ 'buttons.create' | transloco }}"
  matTooltipPosition="above"
  >{{ 'buttons.create' | transloco }}</span
>
<span class="pointer" *ngIf="!!hasFieldValue" matTooltip="Edit" matTooltipPosition="above">{{
  'buttons.edit' | transloco
}}</span>
