import {
  DB,
  DomainInfo,
  NO_TOKEN,
  SiteType,
  UserConnexion,
  UserConnexionInStorage,
} from './../models/auth.model';
import {
  MODULES_PREF_DEFAULT,
  ModulePreferences,
  ModulePreferencesResponse,
  Preferences,
  PreferencesFields,
} from '../modules/settings/models/settings.model';
import { NavigationEnd, Router } from '@angular/router';

import { ARS_SUB_DOMAIN } from '../app.constant';
import { AppConfig } from '../app.config';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_EMPTY_VALUE } from './../config/api.config';
import { LocalStorageService } from './../core/authentication/services/local-storage/local-storage.service';
import { URL_ROUTER } from 'apps/arsultima/src/app/utils/url-router';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // DB info
  private _currentUserConnection = new BehaviorSubject<UserConnexion>(null);
  currentUserConnection$ = this._currentUserConnection.asObservable();

  // Client info (preferences query)

  private _client = new BehaviorSubject<PreferencesFields>(null);
  client$ = this._client.asObservable();

  // Module display preferences
  private _displayPreferences = new BehaviorSubject<ModulePreferences>(null);
  displayPreferences$ = this._displayPreferences.asObservable();

  // Non observable version
  // It is being synchronised in the constructor with the observable version
  currentUserConnection: UserConnexion;

  // In some page the db used to get data is not the db of the connected user
  // but the DB of the page we are in / It is typically the case
  // Of collection of other arsultima account visited when connected to a specific DB
  routeDbOverride: string;

  // TODO: Why duplicating this information ?
  // If we visit collection section (public page)
  dbFromCollectionInUrl = null;

  siteType = SiteType.CLIENT;

  constructor(private readonly router: Router, private localStorageService: LocalStorageService) {
    // Initial calculation of connected user
    this.getCurrentUserConnectionAtInit();

    // After routing is received we recalculate the connected user
    this.setSiteTypeDependingOfURL();

    this.currentUserConnection$.subscribe(userConnection => {
      console.log('New connection', userConnection);
      this.currentUserConnection = userConnection;
    });
  }

  setClient(client: Preferences): void {
    const fields = client.fields;
    this.localStorageService.setClientLocalStorage(JSON.stringify(fields));
    this._client.next(fields);
    this._displayPreferences.next(this.getDisplayPreferencesFromClient(fields));
  }

  getDisplayPreferencesFromClient(fields: ModulePreferencesResponse): ModulePreferences {
    const { dsp_artist, dsp_collec, dsp_interm, dsp_contact } = fields;

    // TODO: to remove when API is refactored to return all true for new user
    if (!dsp_artist && !dsp_collec && !dsp_interm && !dsp_contact) {
      return MODULES_PREF_DEFAULT;
    }

    return {
      artist: Boolean(dsp_artist),
      collector: Boolean(dsp_collec),
      seller: Boolean(dsp_interm),
      contact: Boolean(dsp_contact),
    };
  }

  getCurrentUserConnection(): UserConnexion {
    return this.currentUserConnection;
  }

  getCurrentUserConnectionAtInit(options?: { defaultDb?: string }): UserConnexion {
    const userConnectionFromStorage = this.getConnectionFromLocalstorage();
    let defaultDb: string;
    if (this.siteType === SiteType.CLIENT_FROM_URL) {
      defaultDb = this.dbFromCollectionInUrl;
    } else {
      defaultDb = (options && options.defaultDb) || this.routeDbOverride || null;
    }
    let userConnectionObject: UserConnexion = this.getConnexion(
      userConnectionFromStorage,
      defaultDb,
    );
    // Observer method
    this._currentUserConnection.next(userConnectionObject);

    // Default method (deprecated)
    this.currentUserConnection = userConnectionObject;
    return userConnectionObject;
  }

  // Populate the query params
  // with the connection information
  // of the page or connected user
  // Called from most http queries
  getParamsWithConnexion(options?: { params?: HttpParams; noToken: boolean }): HttpParams {
    const params = options?.params || new HttpParams();
    const noToken = options?.noToken || false;
    const { db, token } = this.getCurrentUserConnection();
    const tokenToUse = !!noToken ? NO_TOKEN : token;

    if (AppConfig.USE_TEST_DB) {
      return this.getDefaultAuthParams(params);
    } else {
      return this.getConnectedUserAuthParams(params, db, tokenToUse);
    }
  }

  getCurrentClient() {
    return this.localStorageService.getClient();
  }

  getApiBase(): DomainInfo {
    const instance = this.getInstance();
    const domainInfo = new DomainInfo(AppConfig.BASE_URL, instance);

    return domainInfo;
  }

  getParamsNoTokenCfag(params: HttpParams = new HttpParams()) {
    params = params.append('db', DB.CFAG);
    params = params.append('token', NO_TOKEN);
    return params;
  }

  private getConnectionFromLocalstorage(): UserConnexion {
    const userConnectionInStorage = this.localStorageService.getConnectedUser();
    const userConnection = this.isValidLocalStorageConnection(userConnectionInStorage)
      ? userConnectionInStorage
      : null;
    return userConnection;
  }

  private isValidLocalStorageConnection(userConnectionInStorage: UserConnexionInStorage): boolean {
    return userConnectionInStorage !== LOCAL_STORAGE_EMPTY_VALUE && !!userConnectionInStorage;
  }

  private getConnexion(userConnectionFromStorage: UserConnexion, defaultDb: string): UserConnexion {
    let userConnectionObject: UserConnexion;
    if (!userConnectionFromStorage) {
      // standard non connected user case
      userConnectionObject = this.getNotConnectedConnexion(defaultDb);
    } else {
      // If defaultDb is passed we override the db store in the local storage.
      // Used for example when accessing the app home page, when connected as lind or other user
      // The user access a page not from his db
      // We then use the no-token as it is thus a public page
      if (defaultDb && userConnectionFromStorage.db !== defaultDb) {
        userConnectionObject = this.getConnexionWhenNavigatingOtherSiteWhileConnected(
          defaultDb,
          userConnectionFromStorage,
        );
      } else {
        // standard connected user case
        userConnectionObject = this.getStandardConnexion(userConnectionFromStorage);
      }
    }
    return userConnectionObject;
  }

  private getNotConnectedConnexion(defaultDb: string): UserConnexion {
    return {
      db: defaultDb ? defaultDb : AppConfig.DEFAULT_DB,
      connectedUserDb: null,
      email: null,
      token: null,
      site: this.siteType,
      canEdit: false,
    };
  }

  private getConnexionWhenNavigatingOtherSiteWhileConnected(
    defaultDb: string,
    userConnectionFromStorage: UserConnexion,
  ): UserConnexion {
    return {
      token: NO_TOKEN,
      db: defaultDb,
      connectedUserDb: userConnectionFromStorage.db,
      email: userConnectionFromStorage.email,
      site: this.siteType,
      canEdit: false,
    };
  }

  private getStandardConnexion(userConnectionFromStorage: UserConnexion) {
    return {
      ...userConnectionFromStorage,
      connectedUserDb: userConnectionFromStorage.db,
      site: this.siteType,
      canEdit: true,
    };
  }

  // Every time we change page this logic is evaluated
  private setSiteTypeDependingOfURL() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = event;
        if (this.isInArsultimaServiceHomePage(url)) {
          this.setSiteTypeAsArsultima();
        } else if (this.isInPublicCollectionPage(url)) {
          this.setSiteTypeAsClientDependingOfUrl(url);
        } else {
          // Other page are private so display depend entirely of the db of the connection
          this.setSiteTypeAsClient();
        }
        this.getCurrentUserConnectionAtInit();
      }
    });
  }

  private isInPublicCollectionPage(url: string): boolean {
    return url.includes(`/${AppConfig.COLLECTION_PATH_PREFIX}/`);
  }

  private isInArsultimaServiceHomePage(url: string): boolean {
    return (
      url.includes(`${URL_ROUTER.arsultimaPrefix()}/`) ||
      url.includes(`/${URL_ROUTER.publicCollections(DB.ARSU)}/`)
    );
  }

  // We are visiting a arsltima page, so the DB should be arsu
  private setSiteTypeAsArsultima() {
    this.routeDbOverride = AppConfig.DEFAULT_DB;
    this.siteType = SiteType.ARSU;
  }

  // We are visiting a client site instance
  private setSiteTypeAsClient() {
    this.routeDbOverride = null;
    this.siteType = SiteType.CLIENT;
  }

  // Collection section is public
  private setSiteTypeAsClientDependingOfUrl(url: string) {
    const collection = url.split('/')[2];
    this.routeDbOverride = null;
    this.dbFromCollectionInUrl = collection;
    this.siteType = SiteType.CLIENT_FROM_URL;
  }

  private getInstance(): string {
    const dbFromParam = this.getDbFromParam();
    const currentUser = this.getCurrentUserConnection();
    return dbFromParam || (currentUser && currentUser.db) || ARS_SUB_DOMAIN;
  }

  private getDbFromParam(): string {
    const params = new URLSearchParams(location.search);
    let dbFromParam = params.get('db');

    if (dbFromParam && (dbFromParam === 'app' || dbFromParam === 'home')) {
      dbFromParam = ARS_SUB_DOMAIN;
    }

    return dbFromParam;
  }

  private getConnectedUserAuthParams(params: HttpParams, db: string, token: string): HttpParams {
    params = params.append('db', db);
    params = params.append('token', token || NO_TOKEN);
    return params;
  }

  private getDefaultAuthParams(params: HttpParams): HttpParams {
    params = params.append('db', 'cfag');
    params = params.append('token', NO_TOKEN);
    return params;
  }
}
