import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DB, UserConnexion } from './../../models/auth.model';
import { ModulePreferences, Preferences } from '../../modules/settings/models/settings.model';

import { ActivatedRoute } from '@angular/router';
import { AppConfig } from './../../app.config';
import { DIALOG_SIZE } from './../../config/dialog.config';
import { DataService } from '../../services/data.service';
import { LangKeys } from './../../config/lang.config';
import { LoginFormComponent } from './../../modules/connect/containers/login-form/login-form.component';
import { MatDialog } from '@angular/material/dialog';
import { NavigateService } from '../../core/authentication/services/navigate/navigate.service';
import { Observable } from 'rxjs';
import { URL_ROUTER } from './../../utils/url-router';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'arsultima-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnChanges {
  @Output()
  signoutClicked = new EventEmitter();

  @Input()
  userConnexion: UserConnexion;

  @Input()
  client: Preferences;

  @Input()
  lang: LangKeys;

  mobileMenuOpened = false;

  limitMobile = 'lg';

  desktopMenu = true;

  isArsultimaSite = false;

  debug = false;

  currentDb: string;
  connectedUserDb: string;
  isSameDbAsConnected: boolean;

  logoUrl = AppConfig.DEFAULT_LOGO;
  logoUrlDynamic = '';

  path: Record<string, string> = {};

  displayPreferences$: Observable<ModulePreferences>;

  constructor(
    public readonly dialog: MatDialog,
    private readonly navigateService: NavigateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly dataService: DataService,
  ) {
    this.displayPreferences$ = this.dataService.displayPreferences$;
  }

  get isNotOnConnectedSite() {
    return !!this.userConnexion && !this.isSameDbAsConnected;
  }

  get showIfNotArsultima() {
    return !this.isArsultimaSite;
  }

  get isArsultima() {
    return this.isArsultimaSite;
  }

  get showArsultimaButton() {
    return !this.isArsultimaSite;
  }

  get isConnected() {
    return !!this.userConnexion?.connectedUserDb;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userConnexion && !!changes.userConnexion.currentValue) {
      this.setHeaderDependingOfUserConnexion(changes.userConnexion.currentValue);
      this.cdr.detectChanges();
    }
    if (changes.client && !!changes.client.currentValue) {
      this.setLogoUrlDynamic();
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.debug = params.debug;

      // Check if the 'loginOpen' parameter is present and open the login dialog
      if (params.loginOpen) {
        this.openLoginDialog();
      }
    });
  }

  toggleMenu() {
    this.mobileMenuOpened = !this.mobileMenuOpened;
  }

  hideMenuOnMobile(isMobile: boolean) {
    if (isMobile) {
      this.toggleMenu();
    }
  }

  signout() {
    this.signoutClicked.emit();
  }

  openLoginDialogOrGoToHomeConnected() {
    if (this.connectedUserDb) {
      this.navigateService.navigate(URL_ROUTER.homeCollection(this.connectedUserDb));
    } else {
      this.openLoginDialog();
    }
  }

  setLogoUrlDynamic() {
    this.logoUrlDynamic = `https://www.arsu.arsultima.com/b/${this.currentDb}/BaseH/H_${
      this.client?.fields?.nom_pic
    }?time=${Date.now()}`;
  }

  private openLoginDialog() {
    const dialogRef = this.dialog.open(LoginFormComponent, {
      width: DIALOG_SIZE.DEFAULT,
    });

    dialogRef.afterClosed().subscribe();
  }

  private initializeHeader(userConnexion: UserConnexion) {
    if (!!userConnexion) {
      const { db } = userConnexion;
      this.setLogoUrl(userConnexion);
      this.setPath(db);
    }
  }

  private isSiteTypeArsultimaType(userConnexion: UserConnexion): boolean {
    return userConnexion.db === DB.ARSU;
  }

  private isArsultimaDb(db: string): boolean {
    return db === DB.ARSU;
  }

  private setHeaderDependingOfUserConnexion(userConnexion: UserConnexion) {
    if (userConnexion && userConnexion.site) {
      this.isArsultimaSite = this.isSiteTypeArsultimaType(userConnexion);
    }
    const { db, connectedUserDb } = userConnexion;
    this.currentDb = db;
    this.connectedUserDb = connectedUserDb;

    this.isSameDbAsConnected = db === connectedUserDb;
    this.initializeHeader(userConnexion);
  }

  private setLogoUrl(userConnexion: UserConnexion): void {
    const { db } = userConnexion;
    const imageSegment = this.isSiteTypeArsultimaType(userConnexion) ? 'arsu' : !!db ? db : AppConfig.DEFAULT_LOGO;
    this.logoUrl = `https://www.arsu.arsultima.com/b/${imageSegment}/Banners/bandomenu.png`;
  }

  private setPath(db: string): void {
    const links = {
      logo: this.isArsultimaDb(db) ? URL_ROUTER.homeArsultima() : URL_ROUTER.homeCollection(this.currentDb),
      // Public page
      exhibit: URL_ROUTER.exhibitWall(db),
      home: this.isArsultimaDb(db) ? URL_ROUTER.homeArsultima() : URL_ROUTER.homeCollection(db),
      discover: URL_ROUTER.discoverArsultima(),
      contact: this.isArsultimaDb(db) ? URL_ROUTER.contactArsultima() : URL_ROUTER.contactCollection(db),

      login: URL_ROUTER.login(),

      // Private page
      database: URL_ROUTER.databaseWorks(),
      stats: URL_ROUTER.stats(),
      settings: URL_ROUTER.settings(),
    };
    this.path = links;
  }
}
