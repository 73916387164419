import { AppConfig } from '../app.config';

export class URL {
  // Authentication

  // CONNECT

  // Used to login
  static authenticate = `${AppConfig.API_BASE_URL}/connect/0?token=notoken`;

  // Used to logout
  static signout = `${AppConfig.API_BASE_URL}/connect/0`;

  // Used to ask for a password reset
  static forgotPassword = `${AppConfig.API_BASE_URL}/password/forgot?db=${AppConfig.DEFAULT_LOGO}&token=notoken`;

  // Used to securize the front page to reset password
  static passwordResetCheck = `${AppConfig.API_BASE_URL}/password/reset/check`;

  // Used to give a new password
  static resestPassword = `${AppConfig.API_BASE_URL}/password/reset/submit?db=${AppConfig.DEFAULT_LOGO}&token=notoken`;

  //  static signup = `${AppConfig.API_BASE_URL}/signup`;
  static signup = `${AppConfig.API_BASE_URL}/connect/account/create?token=notoken`;

  static emailValidate = `${AppConfig.API_BASE_URL}/connect/account/create`;

  // statistics
  static getFilterOptions = `${AppConfig.API_BASE_URL}/stats/filters`;
  static getStatistics = `${AppConfig.API_BASE_URL}/stats/data`;

  // settings
  static getSettings = `${AppConfig.API_BASE_URL}/settings/1`;
  static saveSettings = `${AppConfig.API_BASE_URL}/settings`;
  static uploadSettingsFile = `${AppConfig.API_BASE_URL}/settings`;
  static getPreferences = `${AppConfig.API_BASE_URL}/client/1`;
  static getSelectLists = `${AppConfig.API_BASE_URL}/selects/all`;

  // contacts
  static emailContact = `${AppConfig.API_BASE_URL}/contact/`;

  // works
  static work = `${AppConfig.API_BASE_URL}/oeuvre`;
  static works = `${AppConfig.API_BASE_URL}/oeuvres`;
  static getWorksPublic = `${AppConfig.API_BASE_URL}/oeuvres/public`;
  static getWorksPrivate = `${AppConfig.API_BASE_URL}/oeuvres/private`;
  static getWorkPrivateFilters = `${AppConfig.API_BASE_URL}/oeuvres/privatefilters`;
  static getWorkPublicFilters = `${AppConfig.API_BASE_URL}/oeuvres/publicfilters`;

  static getPublicWorksForExhibit = `${AppConfig.API_BASE_URL}/oeuvres/public`;

  // addresses
  static address = `${AppConfig.API_BASE_URL}/adress`;
  static addresses = `${AppConfig.API_BASE_URL}/adresss`;
  static getAddresses = `${AppConfig.API_BASE_URL}/adresss/private`;

  // artists
  static artist = `${AppConfig.API_BASE_URL}/artist`;
  static artists = `${AppConfig.API_BASE_URL}/artists`;
  static getArtistsPrivate = `${AppConfig.API_BASE_URL}/artists/private`;
  static getArtistPrivateFilters = `${AppConfig.API_BASE_URL}/artists/privatefilters`;

  // collectors
  static collector = `${AppConfig.API_BASE_URL}/collec`;
  static collectors = `${AppConfig.API_BASE_URL}/collecs`;
  static getCollectors = `${AppConfig.API_BASE_URL}/collecs/private`;

  // sellers
  static seller = `${AppConfig.API_BASE_URL}/interm`;
  static sellers = `${AppConfig.API_BASE_URL}/interms`;
  static getSellers = `${AppConfig.API_BASE_URL}/interms/private`;

  // others
  static other = `${AppConfig.API_BASE_URL}/autres`;
  static others = `${AppConfig.API_BASE_URL}/autress`;
  static getOthers = `${AppConfig.API_BASE_URL}/autress/private`;

  // client
  static client = `${AppConfig.API_BASE_URL}/client`;
  static clientLogoUpload(clientId: string) {
    return `${AppConfig.API_BASE_URL}/client/pic/${clientId}/upload`;

    // to test that the form is correctly sending file
    // we use an API that we know is working
    // return `${AppConfig.API_BASE_URL}/oeuvres/pic/0/upload`;
  }
}
