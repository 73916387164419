import { Event, NavigationExtras, Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {
  debug = true;
  events: Observable<Event>;

  history: string[] = [];

  _imageVersion: string;

  // We exclude some urls to not produce some loop in the navigation
  excludedUrl: string[] = ['/viewer', 'viewer'];

  constructor(private readonly router: Router) {
    this.events = this.router.events;
    this.updateImageVersion();
  }

  get imageVersion() {
    return this._imageVersion;
  }

  url() {
    return this.router.url;
  }

  updateImageVersion() {
    this._imageVersion = Date.now().toString();
  }

  navigateBackUrl() {
    this.navigateByUrl(this.history.pop());
  }

  // navigateByURl does not allow to pass https://angular.io/api/router/NavigationExtras
  // So we use navigate in some cases
  navigate(url: string, options: NavigationExtras = {}) {
    if (this.debug) {
      console.log(`NAVIGATE PROGRAMATICALLY to url ${url}`);
      if (!!options) {
        console.log(`with optional options`, options);
      }
    }

    this.saveHistory();

    return this.router.navigate([url], options);
  }

  // Can contain
  navigateByUrl(url: string) {
    if (this.debug) {
      console.log(`NAVIGATE PROGRAMATICALLY to url ${url}`);
    }

    this.saveHistory();

    return this.router.navigateByUrl(url);
  }

  // JS Reload
  reloadPage() {
    if (this.debug) {
      console.log(`NAVIGATE JS RELOAD PAGE`);
    }
    window.location.reload();
  }

  // Only used when we are uploading an image ut keeping in the same page
  // So we just update history with the id of the form when we now it (first save)
  // We assume the id is the last segment of the url
  updateUrlWithId(id: string): string {
    const lastUrl = this.router.url;
    const lastUrlModified = lastUrl.replace(/\/0$/, '/' + id);
    this.saveHistory(lastUrlModified);
    return lastUrlModified;
  }

  private saveHistory(url?: string) {
    if (!this.excludedUrl.includes(this.router.url) || url) {
      const urlToAdd = url || this.router.url;
      if (this.debug) {
        console.log('Route ', urlToAdd, ' was added to history');
      }
      this.history.push(urlToAdd);
      console.log('New history ', this.history);
    } else {
      if (this.debug) {
        console.log('Route ', this.router.url, ' was exclude from history');
      }
    }
  }
}
