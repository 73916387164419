import { Component, Input, OnInit } from '@angular/core';

// import { KeyboardKeys } from '../../../models/keyboard.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'arsultima-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  // @HostListener('document:keydown', ['$event'])
  // handleArrowKeys(event: KeyboardEvent) {
  //   event.preventDefault();
  //   switch (event.key) {
  //     case KeyboardKeys.ESCAPE: {
  //       this.close();
  //       break;
  //     }
  //   }
  // }

  @Input()
  title: string;

  constructor(private readonly matDialogRef: MatDialogRef<DialogComponent>) {}

  ngOnInit(): void {}

  close() {
    this.matDialogRef.close();
  }
}
