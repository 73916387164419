import { AuthInterceptorsService } from './interceptors/auth-interceptors/auth-interceptors.service';
import { AuthService } from './authentication/services/auth/auth.service';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EventsModule } from './events/events.module';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [],
  imports: [CommonModule, EventsModule, TranslocoModule],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorsService,
      multi: true,
    },
  ],
})
export class CoreModule {}
