<ng-container *ngIf="form && hasFieldValue">
  <button
    matSuffix
    mat-icon-button
    [disableRipple]="true"
    aria-label="Clear"
    class="clear m-l-xs"
    (click)="clear($event)"
  >
    <mat-icon class="icon icon-small">close</mat-icon>
  </button>
</ng-container>
