export interface MessageBarState {
  visibility: boolean;
  message: string;
  action?: string; // Optional path for action
}

export const MESSAGE_BAR_DEFAULT = {
  visibility: false,
  message: '',
  action: null,
};
