import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'arsultima-add-edit-delete',
  templateUrl: './add-edit-delete.component.html',
  styleUrls: ['./add-edit-delete.component.scss'],
})
export class AddEditDeleteComponent implements OnInit {
  @Input()
  addEnabled = true;

  @Input()
  editEnabled = true;

  @Input()
  deleteEnabled = true;

  @Input()
  addLabel = 'buttons.add';

  @Input()
  editLabel = 'buttons.edit';

  @Input()
  deleteLabel = 'buttons.delete';

  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() editClick: EventEmitter<any> = new EventEmitter();
  @Output() deleteClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  add(): void {
    if (this.addEnabled) {
      this.addClick.emit();
    }
  }

  edit(): void {
    if (this.editEnabled) {
      this.editClick.emit();
    }
  }

  delete(): void {
    if (this.deleteEnabled) {
      this.deleteClick.emit();
    }
  }
}
