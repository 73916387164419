import { APP_DEFAULT_LANG, CLIENT_LANG_PROPERTY, LangKeys } from '../config/lang.config';

import { Injectable } from '@angular/core';
import { LocalStorageService } from '../core/authentication/services/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { Preferences } from '../modules/settings/models/settings.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(
    private readonly translocoService: TranslocoService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  setActiveLanguage(langCode: LangKeys) {
    this.translocoService.setActiveLang(langCode);
  }

  saveLanguageLocalStorage(langCode: LangKeys) {
    this.localStorageService.setLang(langCode);
  }

  getActiveLanguage(): LangKeys {
    return this.translocoService.getActiveLang() as LangKeys;
  }

  getSubscriptionLangChanges(): Observable<LangKeys> {
    return this.translocoService.langChanges$ as Observable<LangKeys>;
  }

  getBrowserLang(): LangKeys {
    const browserLang = navigator.language.split('-')[0];
    return this.getValidatedLangCode(LangKeys[browserLang]);
  }

  /**
   * Handle the initialization logic
   * For connected and not connect user
   */
  setLangFromClient(client: Preferences) {
    if (client.select.length) {
      this.setLangFromClientPreferences(client);
      return;
    }

    const langCodeLocalStorage = this.localStorageService.getLang();
    const langCodeBrowserPreference = this.getBrowserLang();

    if (langCodeLocalStorage) {
      this.setActiveLanguage(langCodeLocalStorage);
    } else if (langCodeBrowserPreference) {
      this.setActiveLanguage(langCodeBrowserPreference);
    } else {
      this.setActiveLanguage(APP_DEFAULT_LANG);
    }
  }

  private setLangFromClientPreferences(client: Preferences) {
    let langCode: string;
    client.select.forEach(s => {
      if (s.type === CLIENT_LANG_PROPERTY) {
        langCode = s.options[s.selected];
      }
    });
    this.setActiveLanguage(this.getValidatedLangCode(langCode));
  }

  private getValidatedLangCode(langCode: string): LangKeys {
    const allowedLangs = [LangKeys.fr, LangKeys.en] as string[];
    const isLangValid = allowedLangs.includes(langCode);
    return !!isLangValid ? (langCode as LangKeys) : APP_DEFAULT_LANG;
  }
}
