import {
  API_ERRORS_ID,
  ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID,
  SHOW_MESSAGES_ONLY_FOR_KNOWN_API_ERRORS,
} from './../../config/errors.config';

import { ArsultimaErrorTypes } from 'apps/arsultima/src/app/config/errors.config';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SNACKBAR_FAILURE } from './../../config/snackbar.config';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  constructor(private readonly snackBar: MatSnackBar, private translocoService: TranslocoService) {}

  showDefaultError(apiErrorId: API_ERRORS_ID = API_ERRORS_ID.VOID) {
    this.snackBar.open(this.getDefaultMessage(apiErrorId), SNACKBAR_FAILURE);
  }

  showFrontDefinedError(apiErrorId: API_ERRORS_ID) {
    this.snackBar.open(this.getFrontMessage(apiErrorId), SNACKBAR_FAILURE);
  }

  showError(apiErrorId: API_ERRORS_ID) {
    if (this.isErrorKnown(apiErrorId)) {
      this.showFrontDefinedError(apiErrorId);
    } else if (!SHOW_MESSAGES_ONLY_FOR_KNOWN_API_ERRORS) {
      this.showDefaultError(apiErrorId);
    }
  }

  private isErrorKnown(apiErrorId: API_ERRORS_ID): boolean {
    console.log('Error from API with ID ' + apiErrorId);
    return !!ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID[apiErrorId];
  }

  private getFrontMessage(apiErrorId: API_ERRORS_ID): string {
    let errMessage = '';
    const frontId = ARSULTIMA_ERRORS_API_ID_TO_FRONT_ID[apiErrorId];
    if (frontId) {
      errMessage = this.translocoService.translate('error.' + frontId);
    } else {
      errMessage = this.getDefaultMessage(apiErrorId);
    }
    return errMessage;
  }

  private getDefaultMessage(apiErrorId: API_ERRORS_ID): string {
    const errorMessage = this.translocoService.translate('error.' + ArsultimaErrorTypes.DEFAULT_ERROR);

    return !!apiErrorId ? `errorMessage : ${apiErrorId}` : errorMessage;
  }
}
