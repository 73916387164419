export enum IMAGE_FIELDS_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export const IMAGE_FIELDS_DIM = {
  [IMAGE_FIELDS_SIZE.SMALL]: {
    width: 50,
    height: 50,
  },
  [IMAGE_FIELDS_SIZE.MEDIUM]: {
    width: 350,
    height: 350,
  },
};
