import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'arsultima-add-list-item',
  templateUrl: './add-list-item.component.html',
  styleUrls: ['./add-list-item.component.scss'],
})
export class AddListItemComponent implements OnInit {
  @Input()
  type: string;

  constructor() {}

  ngOnInit(): void {}
}
