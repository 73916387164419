import { Pipe, PipeTransform } from '@angular/core';

import { KeyValue } from 'apps/arsultima/src/app/models/key-value.model';

@Pipe({
  name: 'sortByValue',
})
export class SortByValuePipe implements PipeTransform {
  // assume to have key / Va
  transform(options: KeyValue[]): unknown {
    // We ignore non array
    if (!Array.isArray(options)) {
      return options;
    }
    return options.sort((a, b) => {
      const nameA = a?.value?.toLowerCase();
      const nameB = b?.value?.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
}
