import { Component, Input, OnInit } from '@angular/core';

import { HomeCard } from '../../../modules/home/models/home.model';
import { NavigateService } from './../../../core/authentication/services/navigate/navigate.service';

@Component({
  selector: 'arsultima-home-cards',
  templateUrl: './home-cards.component.html',
  styleUrls: ['./home-cards.component.scss'],
})
export class HomeCardsComponent implements OnInit {
  @Input() cards: HomeCard[];

  constructor(private readonly navigateService: NavigateService) {}

  ngOnInit(): void {}

  navigateToPage(path: string): void {
    this.navigateService.navigate(path);
  }
}
