import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NavigateService } from './../../core/authentication/services/navigate/navigate.service';
import { PreferencesFields } from '../../modules/settings/models/settings.model';
import { SettingsService } from '../../services/settings.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'arsultima-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
  @Input()
  path: string;

  @Input()
  logoUrl: string;

  @Input()
  isEditable: boolean;

  @Input()
  client: PreferencesFields;

  @Input()
  currentDb: string;

  @Output()
  logoUploaded: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly settingsService: SettingsService, private navigateService: NavigateService) {}

  ngOnInit(): void {}

  get _logoUrl(): string {
    if (this.logoUrl) {
      return this.logoUrl;
    } else {
      if (this.currentDb && this.client) {
        // IS CAUSING BUG AT RUN TIME
        // return `https://www.arsu.arsultima.com/b/${this.currentDb}/BaseH/H_${this.client?.nom_pic}?v=${Date.now()}`;
        return `https://www.arsu.arsultima.com/b/${this.currentDb}/BaseH/H_${this.client?.nom_pic}?v=${this.navigateService.imageVersion}`;
      }
    }
  }

  fileSelected($event: any): void {
    this.settingsService.uploadLogo({ file: $event[0], clientId: this.client?.ID }).subscribe(() => {
      // When CDN is setup would not be needed
      // But current allow to fix
      // https://github.com/sinsunsan/arsultima/issues/348
      this.navigateService.updateImageVersion();
      this.navigateService.reloadPage();
      this.logoUploaded.emit();
    });
  }
}
