<div class="d-none d-sm-flex m-l-md">
  <mat-icon
    (click)="add()"
    class="button-icon"
    matRipple
    [matTooltip]="addLabel | transloco"
    [ngClass]="{ disabled: !addEnabled }"
    >add</mat-icon
  >
  <mat-icon
    (click)="edit()"
    class="button-icon"
    matRipple
    [matTooltip]="editLabel | transloco"
    [ngClass]="{ disabled: !editEnabled }"
    >edit</mat-icon
  >
  <mat-icon
    (click)="delete()"
    class="button-icon"
    matRipple
    [matTooltip]="deleteLabel | transloco"
    [ngClass]="{ disabled: !deleteEnabled }"
    >delete</mat-icon
  >
</div>
<div class="d-block d-sm-none m-l-xs">
  <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="add()" [matTooltip]="addLabel | transloco" [ngClass]="{ disabled: !addEnabled }">
      <mat-icon class="button-icon">add</mat-icon>
      <span>{{ 'buttons.add' | transloco }}</span>
    </button>
    <button mat-menu-item (click)="edit()" [matTooltip]="editLabel | transloco" [ngClass]="{ disabled: !editEnabled }">
      <mat-icon class="button-icon">edit</mat-icon>
      <span>{{ 'buttons.edit' | transloco }}</span>
    </button>
    <button
      mat-menu-item
      (click)="delete()"
      [matTooltip]="deleteLabel | transloco"
      [ngClass]="{ disabled: !deleteEnabled }"
    >
      <mat-icon class="button-icon">delete</mat-icon>
      <span>{{ 'buttons.delete' | transloco }}</span>
    </button>
  </mat-menu>
</div>
