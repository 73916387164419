import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CollectionFilterOption } from '../../../modules/database/models/collection.model';
import { ListFilter } from './list-filters.model';

@Component({
  selector: 'arsultima-list-filter',
  templateUrl: './list-filter.component.html',
  styleUrls: ['./list-filter.component.scss'],
})
export class ListFilterComponent implements OnInit {
  options: CollectionFilterOption[];
  allLabel = 'All';
  label = '';

  @Input()
  set filter(filter: ListFilter) {
    this.options = filter.options;
    if (filter.allLabel) {
      this.allLabel = filter.allLabel;
    }
    if (filter.selectedValue) {
      this.selected = !!filter.selectedValue ? filter.selectedValue : undefined;
    }
    this.label = filter.label;
  }

  @Input()
  selected: number;

  @Output()
  optionSelected = new EventEmitter<CollectionFilterOption>();

  filterDefault = {
    allLabel: 'All',
  };

  constructor() {}

  ngOnInit(): void {}

  selectOption() {
    this.optionSelected.emit(this.options.find(option => option.id === this.selected));
  }
}
