<div class="link-field flex-center" [formGroup]="form">
  <mat-form-field class="field-width-big">
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControlName]="field">
      <mat-option *ngFor="let option of _options | sortByValue" [value]="option.key">
        {{ option.value }}
      </mat-option>
    </mat-select>
    <arsultima-clear-field matSuffix [form]="form" [field]="field"></arsultima-clear-field>
  </mat-form-field>
  <div class="link-buttons">
    <arsultima-create-edit-button
      [showCreate]="!createHidden"
      [showEdit]="!editHidden"
      [showInfo]="!!infoTooltip"
      [infoTooltip]="infoTooltip"
      [createDisabled]="isCreateDisabled"
      [createTooltip]="createTooltip"
      [editDisabled]="isEditDisabled"
      [editTooltip]="editTooltip"
      (createClicked)="emitCreate()"
      (editClicked)="emitEdit()"
    ></arsultima-create-edit-button>
  </div>
</div>
