import { Component, Input, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';

@Component({
  selector: 'arsultima-edit-create-button',
  templateUrl: './edit-create-button.component.html',
  styleUrls: ['./edit-create-button.component.scss'],
})
export class EditCreateButtonComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  field: string;

  get hasFieldValue() {
    if (this.form.get(this.field).value) {
      return true;
    }
    return false;
  }

  constructor() {}

  ngOnInit(): void {}
}
