import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppEventType, AppEvent } from './events.model';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private readonly eventBrocker = new Subject<AppEvent<any>>();

  constructor(private readonly router: Router) {
    this.dispatchCurrentRoute();
  }

  on(eventType: AppEventType): Observable<AppEvent<any>> {
    return this.eventBrocker.pipe(filter(event => event.type === eventType));
  }

  dispatch<T>(event: AppEvent<T>): void {
    this.eventBrocker.next(event);
  }

  private dispatchCurrentRoute() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.dispatch<string>({
        type: AppEventType.CURRENT_ROUTE,
        payload: event.url,
      });
    });
  }
}
