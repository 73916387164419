<div class="logo-wrapper m-l-lg m-r-lg flex-center show-hover-parent overlay-on-hover-parent">
  <ng-container *ngIf="path">
    <a [routerLink]="path" class="logo-wrapper-inner logo overlay-on-hover-child m-0 p-0"
      ><img src="{{ _logoUrl }}"
    /></a>
  </ng-container>
  <ng-container *ngIf="!path">
    <div class="logo-wrapper-inner logo overlay-on-hover-child m-0 p-0">
      <img src="{{ _logoUrl }}" />
    </div>
  </ng-container>
  <arsultima-ui-button-edit-contextual
    *ngIf="isEditable"
    [tooltip]="'Change logo'"
    [darkTheme]="true"
    [onHover]="false"
    [showLabel]="false"
    (click)="fileUpload.click()"
    class="show-hover-child"
    [tooltip]="'buttons.uploadLogo' | transloco"
  >
  </arsultima-ui-button-edit-contextual>
  <input
    type="file"
    accept="image/*,application/pdf"
    class="d-none"
    #fileUpload
    (change)="fileSelected(fileUpload.files)"
  />
</div>
