import { APP_LANGUAGES_DISPLAY, LangKeys } from '../../../config/lang.config';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'langDisplay',
})
export class LangDisplayPipe implements PipeTransform {
  transform(value: LangKeys): unknown {
    return APP_LANGUAGES_DISPLAY[value] ? APP_LANGUAGES_DISPLAY[value] : value;
  }
}
