<div class="cards-container">
  <div class="row justify-content-center">
    <div class="mat-card col-4 card" *ngFor="let card of cards" (click)="navigateToPage(card.path)">
      <div class="mat-card-content">
        <h3 class="d-none d-lg-block">{{ card.title }}</h3>
        <mat-icon class="icon">{{ card.icon }}</mat-icon>
      </div>
    </div>
  </div>
</div>
