<arsultima-dialog [title]="'markdownHelper.dialogTitle' | transloco">
  <section class="content">
    <div class="style" *ngFor="let field of fields">
      <div class="title" [innerHtml]="getTranslation(field, 'title')"></div>
      <em [innerHtml]="getTranslation(field, 'em')"></em>
      <code [innerHtml]="getTranslation(field, 'code')"></code>
      <div class="preview" [innerHtml]="getTranslation(field, 'example')"></div>
    </div>
  </section>
</arsultima-dialog>
