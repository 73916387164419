import { DB } from './models/auth.model';
import { Injectable } from '@angular/core';
import { LOCAL_STORAGE_KEYS } from './config/api.config';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  static BASE_URL = environment.baseUrl;
  static API_BASE_URL = environment.apiBaseUrl;

  static API_BASE_URL2 = environment.apiBaseUrl2; // v2 of the API not it use

  static USE_TEST_DB = false; // Force to use default DB for testing purpose (Default : false)

  static LOCALSTORAGE_USER_KEY = LOCAL_STORAGE_KEYS.LOCALSTORAGE_USER_KEY;
  static LOCALSTORAGE_CLIENT_KEY = LOCAL_STORAGE_KEYS.LOCALSTORAGE_CLIENT_KEY;
  static LOCALSTORAGE_COLLECTION_KEY = LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION;
  static LOCALSTORAGE_EXHIBIT_KEY = LOCAL_STORAGE_KEYS.LOCALSTORAGE_EXHIBIT;
  static LOCALSTORAGE_LANG = LOCAL_STORAGE_KEYS.LOCALSTORAGE_LANG;

  static DEFAULT_DB = DB.ARSU;
  static DEFAULT_LOGO = 'arsu';

  static COLLECTION_PATH_PREFIX = 'collection';
}
