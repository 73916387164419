<h1 mat-dialog-title>
  <div class="row">
    <div class="col-md-10 col-8">
      <span class="text-dialog-header">
        {{ 'connect.emailSent' | transloco }}
      </span>
    </div>
  </div>
</h1>
<hr />
<div class="container mt-4">
  <div class="row">
    <p>{{ 'connect.sentNotif' | transloco }}.</p>
    <button class="full-width" mat-flat-button color="primary" mat-dialog-close>
      OK
    </button>
  </div>
</div>
