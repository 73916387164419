import { STYLES } from './theme-demo.constant';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'arsultima-theme-demo',
  templateUrl: './theme-demo.component.html',
  styleUrls: ['./theme-demo.component.scss'],
})
export class ThemeDemoComponent implements OnInit {
  constructor() {}

  styles = STYLES;

  ngOnInit(): void {}
}
