import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { API_TOKEN_ERRORS } from './../../../config/errors.config';
import { AuthService } from '../../authentication/services/auth/auth.service';
import { ErrorsService } from '../../errors/errors.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorsService implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private readonly errorsService: ErrorsService) {}

  // https://medium.com/angular-in-depth/top-10-ways-to-use-interceptors-in-angular-db450f8a62d6
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(_response => {
        // Successful case
      }),
      catchError((error: HttpErrorResponse) => {
        // Request with http ERROR
        const apiErrorId = (error as any)?.error?.data?.err?.id;
        console.error('ERROR ID is', apiErrorId);

        if (API_TOKEN_ERRORS.includes(apiErrorId)) {
          this.authService.signoutAfterWrongOrDiscardedToken();
        }

        this.errorsService.showError(apiErrorId);
        return throwError(error);
      }),
    );
  }
}
