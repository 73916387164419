<div class="d-flex mt-lg" [ngClass]="{ 'justify-content-center': alignCenter, 'justify-content-end': !alignCenter }">
  <button type="button" mat-flat-button class="cancel-button m-r-md" [disabled]="disableCancel" (click)="cancel()">
    {{ cancelLabel | transloco }}
  </button>
  <button type="submit" mat-flat-button color="primary" [disabled]="disableSaveCombined" (click)="save()">
    {{ saveLabel | transloco }}
  </button>
</div>
<div *ngIf="showDelete">
  <div class="d-flex justify-content-end pt-3 m-r-lg">
    <button
      type="button"
      mat-icon-button
      title="delete"
      [disabled]="disableDelete"
      (click)="delete()"
      class="delete-button flex-center"
      disableRipple
    >
      <mat-icon>delete</mat-icon> <span class="m-l-xxs">{{ deleteLabel | transloco }}</span>
    </button>
  </div>
</div>
