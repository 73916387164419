import { Component, Input, OnInit, SimpleChange } from '@angular/core';

import { LangKeys } from '../../config/lang.config';
import { MatSelectChange } from '@angular/material/select';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'arsultima-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
})
export class LangSwitcherComponent implements OnInit {
  @Input()
  lang: LangKeys;

  currentLang: LangKeys;

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {}

  ngOnChanges({ lang }: { lang: SimpleChange }) {
    if (lang && lang.currentValue) {
      this.currentLang = lang.currentValue;
    }
  }

  get isEnglish(): boolean {
    return this.getLanguage() === LangKeys.en;
  }

  get isFrench(): boolean {
    return this.getLanguage() === LangKeys.fr;
  }

  setLanguage(): void {
    this.currentLang = this.getLanguage();
  }

  getLanguage(): LangKeys {
    return this.translationService.getActiveLanguage();
  }

  changeLanguage(select: MatSelectChange): void {
    const lang = select?.value;
    this.translationService.setActiveLanguage(lang);
    this.translationService.saveLanguageLocalStorage(lang);
    this.setLanguage();
  }
}
