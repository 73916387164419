import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MarkdownHelperInfoComponent } from '../markdown-helper-info/markdown-helper-info.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'arsultima-markdown-helper',
  templateUrl: './markdown-helper.component.html',
  styleUrls: ['./markdown-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkdownHelperComponent implements OnInit {
  constructor(private readonly matDialog: MatDialog) {}

  ngOnInit(): void {}

  open() {
    this.matDialog.open(MarkdownHelperInfoComponent);
  }
}
