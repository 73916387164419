import { DatabaseTableFilter, ExternalFiltersState } from './../modules/database/models/table.model';

import { FiltersInLocalStorage } from '../modules/database/models/table.model';
import { HttpParams } from '@angular/common/http';
import { LOCAL_STORAGE_KEYS } from './../config/api.config';
import { SharedUtils } from '@arsultima/ui';

export class Utils {
  static toHttpParams(params: HttpParams, additionalParams?: any): HttpParams {
    if (!!additionalParams) {
      const keys = Object.keys(additionalParams);

      keys.forEach(key => {
        if (additionalParams[key] != undefined) {
          params = params.append(key, additionalParams[key]);
        }
      });
    }

    return params;
  }

  static objectToKeyValue(data: object, key: string = 'key', value: string = 'value'): any[] {
    const result = Object.keys(data).map(function (dataKey) {
      let keyValue = {};
      keyValue[key] = dataKey;
      keyValue[value] = data[dataKey];

      return keyValue;
    });

    return result;
  }

  // get the selected filters of the current collection from localStorage
  static getCollectionFiltersFromStorage(type: string): FiltersInLocalStorage {
    let collection = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION));

    if (!collection || !collection[type]) {
      return null;
    }

    return collection[type];
  }

  static deleteFiltersInLocalStorage(type: string) {
    let collection = this.getStorageCollection();
    collection[type] = {};
    this.saveCollectionInStorage(collection);
  }

  // Save a single filter change in local storage
  static saveFilterInLocalStorage(type: string, filterKey: string, filterValue: number) {
    let collection = this.initCollection(type, 'filters');
    if (!!filterValue) {
      // Set filter
      collection[type].filters[filterKey] = filterValue;
    } else {
      // Unset this specific filter
      delete collection[type].filters[filterKey];
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION, JSON.stringify(collection));
  }

  // Partial tableFilters can be passed
  static saveTableFilterInLocalStorage(type: string, tableFilters: DatabaseTableFilter) {
    const collection = this.getStorageCollection();

    if (!collection[type]) {
      collection[type] = {
        tableFilters,
      };
    } else {
      collection[type] = {
        ...collection[type],
        tableFilters: {
          ...collection[type].tableFilters,
          ...tableFilters,
        },
      };
    }
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION, JSON.stringify(collection));
  }

  static initCollection(type: string, filterType: 'filters' | 'tableFilters') {
    let collection = this.getStorageCollection();
    collection[type] = collection[type] || {};
    collection[type].filters = collection[type][filterType] || {};
    return collection;
  }

  /**
   * Return the current page, given the total number of items, the number of items per page
   * And the index in the total collection of the current item
   * Could only be 1 or more
   */
  static getPageNumber(itemIndex: number, itemPerPage: number): number {
    const currentPage = Math.ceil((itemIndex + 1) / itemPerPage);
    return currentPage;
  }

  static setCollectionFiltersInStorage(type: string, filters: ExternalFiltersState) {
    let collection = this.getStorageCollection();
    collection[type].filters = filters;
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION, JSON.stringify(collection));
  }

  static setCollectionTableFiltersInStorage(type: string, tableFilters: DatabaseTableFilter) {
    let collection = this.getStorageCollection();
    collection[type].tableFilters = tableFilters;
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION, JSON.stringify(collection));
  }

  // TODO: define the collection type
  static saveCollectionInStorage(collection: FiltersInLocalStorage) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION, JSON.stringify(collection));
  }

  static getStorageCollection(): FiltersInLocalStorage {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.LOCALSTORAGE_COLLECTION)) || {};
  }

  // Workaround for https://github.com/sinsunsan/arsultima/issues/215
  // The api sometimes return null as "null"
  static isTrueIgnoringNullAsString(value: any) {
    return !!value && value !== 'null';
  }

  static addRefreshParamToImage(imageUrl: string) {
    return `${imageUrl}?refresh=${new Date().getTime()}`;
  }

  // General function that may be used in LIBS
  static removeAccents = SharedUtils.removeAccents;
}
