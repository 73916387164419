import { MatSnackBarConfig } from '@angular/material/snack-bar';

export const MATERIAL_FIELD_CONFIG = {
  appearance: 'fill',
};

export const MATERIAL_SNACKBAR_CONFIG: MatSnackBarConfig = {
  duration: 4000, // 4s
  panelClass: ['multiline'], // add the .multiline class to the snackbar to render \n line break
};
