import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EventService } from './event.service';

@NgModule({
  declarations: [],
  providers: [EventService],
  imports: [CommonModule],
})
export class EventsModule {}
