import { Component, Input, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';

@Component({
  selector: 'arsultima-clear-field',
  templateUrl: './clear-field.component.html',
  styleUrls: ['./clear-field.component.scss'],
})
export class ClearFieldComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  field: string;

  constructor() {}

  ngOnInit(): void {}

  get hasFieldValue() {
    if (this.field && this.form.get(this.field) && this.form.get(this.field).value) {
      return true;
    }
    return false;
  }

  clear($event: MouseEvent): void {
    if ($event && $event.stopPropagation) {
      $event.stopPropagation();
    }
    this.clearValueFromForm(this.field);
  }

  private clearValueFromForm(field: string): void {
    this.form.get(field).reset();
    this.form.get(field).markAsDirty();
  }
}
