import { AppConfig } from '../app.config';

export enum IMAGES_FILENAME_PREFIX {
  'slides' = 'slides',
  'blocks' = 'blocks',
}

export enum IMAGE_SIZE {
  'small' = 'small',
  'medium' = 'medium',
  'big' = 'big',
}

export const IMAGE_PREFIXES = {
  [IMAGE_SIZE.big]: '/BaseH/H_',
  [IMAGE_SIZE.medium]: '/BaseM/M_',
  [IMAGE_SIZE.small]: '/BaseL/L_',
};

export const IMAGE_EXTENSION = '.JPG';
export const IMAGE_PREFIX = AppConfig.BASE_URL + '/b/';
