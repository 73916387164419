import {
  AddEditDeleteDialogData,
  FormSelectedValue,
} from '../containers/select-lists/select-lists.model';

export interface Address {
  id: string;
  name: string;
}

export interface Preferences {
  ID: string;
  fields: PreferencesFields;
  related: {
    options: Address[];
    selected: number;
    type: string;
    value: {
      adr1: string;
      adr2: string;
      cp: string;
      pays: string;
      ville: string;
    };
  }[];
  select: Array<{
    options: { [key: string]: string };
    selected: string;
    type: string;
  }>;
}

// RESPONSE FROM API
interface ClientInformationResponse {
  ID: string;
  adr_mail: string;
  date_maj: string;
  db_title: string;
  id_logins: number;
  nom_cli: string;
  language: string;
  nom_pic: string;
  org_cli: string;
  prenom_cli: string;
  sous_dom: string;
  site_web?: string;
  phone1?: string;
}

export interface ModulePreferencesResponse {
  dsp_artist?: 1 | 0 | undefined;
  dsp_collec?: 1 | 0 | undefined;
  dsp_interm?: 1 | 0 | undefined;
  dsp_others?: 1 | 0 | undefined;
  dsp_contact?: 1 | 0 | undefined;
}

export interface PreferencesFields
  extends ClientInformationResponse,
    ModulePreferencesResponse,
    ClientStripeInfo {}

// PAYLOAD TO API
interface ClientInformationPayload {
  nom_cli: string;
  prenom_cli: string;
  language: string;
  adr_mail: string;
  org_cli: string;
  db_title: string;
  id_adress: string;
  log_mdp?: string;
  site_web: string;
  phone1?: string;
}

interface ModulePreferencesPayload {
  dsp_artist?: boolean;
  dsp_collec?: boolean;
  dsp_interm?: boolean;
  dsp_others?: boolean;
  dsp_contact?: boolean;
}

export interface ClientStripeInfo {
  plan_id: string;
  subscription_id?: string;
  customer_id?: string;
}

export interface PreferencesPayload extends ClientInformationPayload, ModulePreferencesPayload {}

export interface SelectListWrapper {
  select: SelectList[];
}

export type SelectListRecord = Record<string, SelectList>;

export interface SelectList {
  options: string[];
  type: string;
}

export const enum SelectListFields {
  SITE_WEB = 'site_web',
  ID_ADRESS = 'id_adress',
  TYPE = 'type',
  LOCATION = 'location',
  NATIONALITY = 'nationality',
  CURRENCY = 'currency',
  COUNTRY = 'country',
  ADRESS = 'adress',
}

export interface SelectListUpdatePayload {
  dialogData: AddEditDeleteDialogData;
  optionsList: SelectListRecord;
  updatedValue: FormSelectedValue;
}

export interface ModulePreferences {
  artist: boolean;
  collector: boolean;
  seller: boolean;
  contact: boolean;
}

export const MODULES_PREF_DEFAULT: ModulePreferences = {
  artist: true,
  collector: true,
  seller: true,
  contact: true,
};
