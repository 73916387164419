import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterPipe',
})
export class FilterPipe implements PipeTransform {
  transform(data: any[], value: any, properties: string[]): any {
    if (!value) {
      return data;
    }
    return data.filter(item => {
      for (const property of properties) {
        if (property in item) {
          if (!!item[property] && item[property].toString().toLowerCase().includes(value.toLowerCase())) {
            return true;
          }
        } else {
          console.error(`Property ${property} does not exist in the data test, please check your table configuration.`);
        }
      }
      return false;
    });
  }
}
