import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'arsultima-block-separator',
  templateUrl: './block-separator.component.html',
  styleUrls: ['./block-separator.component.scss'],
})
export class BlockSeparatorComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
