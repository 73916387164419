import { Component, Input, OnInit } from '@angular/core';

import { FormGroup } from '@angular/forms';

@Component({
  selector: 'arsultima-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
})
export class FormFieldErrorComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  name: string;

  @Input()
  field: string;

  constructor() {}

  get formControl() {
    const control = this.form.get(this.field);
    return control;
  }

  ngOnInit(): void {}
}
