<div mat-dialog-content class="mb-3">
  <div>
    <mat-icon>error_outline</mat-icon>
  </div>
  <h1>Discard changes?</h1>
</div>
<div class="row">
  <div class="col-md-6">
    <button
      type="button"
      mat-flat-button
      class="cancel w-100"
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
  <div class="col-md-6">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      class="w-100"
      (click)="onSave()"
    >
      Yes, discard it!
    </button>
  </div>
</div>
