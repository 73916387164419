import { BehaviorSubject, Observable } from 'rxjs';
import { DB, UserConnexion } from './../../../../models/auth.model';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

import { APIHttpResponse } from './../../../../core/models/http.model';
import { ApiResponse } from './../../../../models/api.model';
import { AuthResponse } from './../../models/auth.model';
import { DataService } from 'apps/arsultima/src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from './../local-storage/local-storage.service';
import { NavigateService } from '../navigate/navigate.service';
import { SignupPayload } from './../../../../modules/connect/models/connect.model';
// import { Location } from '@angular/common';
import { URL } from 'apps/arsultima/src/app/utils/url';
import { URL_ROUTER } from 'apps/arsultima/src/app/utils/url-router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  debug = true;

  private _isConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isConnected$: Observable<boolean> = this._isConnected$.asObservable().pipe(distinctUntilChanged());

  private _connectedUser$: BehaviorSubject<UserConnexion> = new BehaviorSubject<UserConnexion>(null);
  connectedUser$: Observable<UserConnexion> = this._connectedUser$.asObservable().pipe(distinctUntilChanged());

  constructor(
    private readonly http: HttpClient,
    private readonly dataService: DataService,
    private readonly navigateService: NavigateService,
    private readonly localStorageService: LocalStorageService, // private location: Location,
  ) {}

  authenticate({ email, password }: { email: string; password: string }) {
    const params = {
      data: {
        log_ident: email,
        log_mdp: password,
      },
    };
    return this.http.put(URL.authenticate, params).pipe(
      tap((response: APIHttpResponse<AuthResponse>) => {
        this.saveTokenInLocalStorage(response, email);
        const user = this.localStorageService.getConnectedUser();
        this._connectedUser$.next(user);
        this._isConnected$.next(true);
        // Redirect to database page which is private
        this.navigateService.navigate(URL_ROUTER.databaseWorks());
        // this.location.();
      }),
    );
  }

  forgotPassword(data: string) {
    const params = {
      data,
    };
    return this.http.post(URL.forgotPassword, params);
  }

  resetPassword(password: string, token: string) {
    const params = {
      password,
      token,
    };
    return this.http.post(URL.resestPassword, { data: params });
  }

  passwordTokenCheck(token: string) {
    const params = {
      token,
    };
    return this.http.post(URL.passwordResetCheck, { data: params });
  }

  signout() {
    const db = this.localStorageService.getConnectedUser()?.db || DB.ARSU;
    const params = this.dataService.getParamsWithConnexion();
    return this.http.delete(URL.signout, { params }).subscribe(() => {
      this.clearLocalStorage();
      this.setUserAsDisconnected();

      this.navigateService.navigate(URL_ROUTER.homeDisconnectedUser(db));
    });
  }

  // Connection state management
  // Probably should be centralize in data management service
  isConnected(): boolean {
    const user: UserConnexion = this.localStorageService.getConnectedUser();
    return !!(user && user.email && user.token && user.db);
  }

  getInitialConnection() {
    if (this.isConnected()) {
      this._isConnected$.next(true);
    } else {
      this._isConnected$.next(false);
    }
  }

  signoutAfterWrongOrDiscardedToken() {
    this.localStorageService.clear();
    // this.navigateService.navigate(URL_ROUTER.homeArsultima());
    this.navigateService.reloadPage();
  }

  signup(payload: SignupPayload): Observable<any> {
    const data = this.getSignupApiPayload(payload);
    let url = URL.signup;
    return this.http.post<ApiResponse<any>>(url, data).pipe(
      map(response => {
        console.log('response signup', response);
        return response;
      }),
    );
  }

  emailValidate(token: string): Observable<any> {
    let url = `${URL.emailValidate}?token=${token}`;

    return this.http.get<ApiResponse<any>>(url).pipe(
      map(response => {
        console.log('response email Validate', response);
        return response;
      }),
    );
  }

  private getSignupApiPayload(payload: SignupPayload): any {
    return {
      data: {
        log_ident: payload.email,
        log_mdp: payload.password,
        clie_dom: payload.subdomain,
      },
    };
  }

  private clearLocalStorage(): void {
    this.localStorageService.clear();
  }

  private setUserAsDisconnected() {
    this._connectedUser$.next(null);
    this._isConnected$.next(false);
  }

  private saveTokenInLocalStorage(response: APIHttpResponse<AuthResponse>, email: string) {
    if (!response || !response.data) {
      this.localStorageService.deleteUserLocalStorage();
    } else {
      const { token, db } = response.data;
      const currentUser = { email, token, db };
      this.localStorageService.setUserLocalStorage(JSON.stringify(currentUser));
    }
  }
}
