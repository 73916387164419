import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'arsultima-create-edit-button',
  templateUrl: './create-edit-button.component.html',
  styleUrls: ['./create-edit-button.component.scss'],
})
export class CreateEditButtonComponent implements OnInit {
  @Input()
  editLabel = 'Edit';

  @Input()
  createLabel = 'Create';

  @Input()
  showCreate = false;

  @Input()
  showEdit = false;

  @Input()
  showInfo = false;

  @Input()
  createDisabled = false;

  @Input()
  editDisabled = false;

  @Input()
  createTooltip: 'Create';

  @Input()
  editTooltip = 'Edit';

  @Input()
  infoTooltip = 'Info';

  @Output()
  createClicked = new EventEmitter();

  @Output()
  editClicked = new EventEmitter();

  constructor(
    readonly translocoService: TranslocoService, //  private readonly translocoService: TranslocoService
  ) {
    // this will override the value
    // But will be overriden if the binding is used
    this.editLabel = this.translocoService.translate('buttons.edit');
    this.createLabel = this.translocoService.translate('buttons.create');
  }

  ngOnInit(): void {}

  onCreateClicked(event: Event): void {
    event.stopPropagation();
    this.createClicked.emit();
  }

  onEditClicked(event: Event): void {
    event.stopPropagation();
    this.editClicked.emit();
  }
}
